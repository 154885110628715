import Button, { ButtonProps } from '../Button';
import ErrorMessage from '../Form/ErrorMessage';
import FormGroup from '../Form/FormGroup';
import FormTextTip from '../Form/FormTextTip';
import LabelText from '../Form/LabelText';
import Col from '../Grid/Col';
import Row from '../Grid/Row';
import AddressButton from '../Input/AddressButton';
import InputRadio from '../Input/InputRadio';
import InputText from '../Input/InputText';
import { MyRewardControllerService } from '@/__generated__/FrontApi';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { FormButton, RightCol } from '@/page-blocks/auth/AuthCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { MyRewardVo } from '@/private-pages/mypage/reward-list';
import { fourthRegex } from '@/helpers/ValidationHelper';

const HistoryItem = styled.li`
  display: table;
  width: 100%;
  table-layout: fixed;
  padding: 32px 0;
  position: relative;
  border-bottom: 1px solid #b7b8ba;
  ${breakpoint(800)} {
    display: block;
    padding: 24px 0;
  }
  ${Button} {
    max-width: none;
    width: auto;
    ${breakpoint(640)} {
      padding: 7px;
      font-size: 14px;
    }
  }
`;
const AddressForm = styled(FormGroup)`
  width: 544px;
  margin-top: 24px;
  ${FormButton} {
    margin-top: 0;
    width: 160px;
  }
  ${breakpoint(800)} {
    width: 100%;
  }
`;
const InputTextCustom = styled.div<{ active?: boolean }>`
  border: 1px solid #e5e6e8;
  margin-top: -1px;
  ${({ active }) => {
    if (!active) {
      return `
            span {
              color: #2d2926 !important;
            }
          `;
    }
    return ``;
  }}
`;
const Tip = styled.p`
  color: #828385;
  font-size: 14px;
  font-weight: normal;
`;
const Cell = styled.div<{ type?: string; status?: string; price?: boolean }>`
  display: table-cell;
  ${InputTextCustom} {
    span {
      width: 10%;
      text-align: right;
      color: #b7b8ba;
    }
  }
  ${({ type }) => {
    if (type === `date` || type === `status`) {
      return `
            width: 15%;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            .sky {
              color: #1cabe2
            }
          `;
    }
    if (type === `reward`) {
      return `
        font-size: 16px;   
        width: 10%;   
        color: #828385;
      `;
    }
    if (type === `address`) {
      return `
        flex-grow: 4;
      `;
    }
    if (type === `address-btn`) {
      return `
        flex-grow: 1;
        text-align: right;
        ${Tip} {
          color: #828385 !important;
          margin-top: 16px;
        }
        ${Button} {
            margin-top: 0;
            min-width: 80px;
            margin-left: 10px;
            :first-child {
              margin-left: 0;
            }
        }
      `;
    }
  }}
  ${({ price }) => {
    if (price) {
      return `
            width: 40%;
            padding-right: 16px;
            ${InputText} {
              border: none;
              width: 90%;
              display: inline-block;
            }
          `;
    }
    return ``;
  }}
  ${({ status }) => {
    if (status === `dark`) {
      return `
            color: #2d2926
          `;
    }
    if (status === `light`) {
      return `
            color: #828385;
          `;
    }
    return ``;
  }}
  ${breakpoint(1024)} {
    ${({ type }) => {
      if (type === `address-btn`) {
        return `
            vertical-align: bottom;
          `;
      }
      return ``;
    }}
  }
  ${breakpoint(800)} {
    display: block;
    ${({ status }) => {
      if (status === `dark` || status === `light`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
          `;
      }
      return ``;
    }}
    ${({ type }) => {
      if (type === `date`) {
        return `
        text-align: left;
          `;
      }
      if (type === `status`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
            width: 100%;
          `;
      }
      if (type === `address-btn`) {
        return `
            width: 100%;
            text-align: right;
            ${Tip} {
                margin-top: 8px;
            }
        `;
      }
      return `padding-left: 0;`;
    }}
  }
  ${breakpoint(640)} {
    ${({ type }) => {
      if (type === `address-btn`) {
        return `
        ${Tip} {
            font-size: 12px;
        }
        `;
      }
    }}
  }
`;
const Category = styled.div`
  min-height: 44px;
  line-height: 2;
  color: #828385;
  strong {
    color: #1cabe2;
    display: inline-block;
    margin-left: 40px;
    &.first {
      margin-left: 0;
    }
  }
  ${Button} {
    margin-left: 32px;
    ${breakpoint(640)} {
      display: block;
      margin-left: 90px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
    }
  }
  ${breakpoint(640)} {
    margin-top: 18px;
  }
`;
const PriceDetail = styled.div`
  margin-top: 18px;
  span {
    position: relative;
    padding-right: 16px;
    &.is-failed {
      color: #e2231a;
    }
    & + span {
      padding-left: 16px;
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 16px;
        background: #e5e6e8;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
`;
const DueDateText = styled.div`
  color: #e2231a;
  margin-top: 16px;
  flex-grow: 4;
`;
const AgreeCheckFlex = styled.div`
  margin: 16px 0 32px 0;
  label {
    width: 50%;
  }
`;
const RewardDefaultContainer = styled.div`
  display: flex;
  ${Cell} {
    flex-grow: 1;
  }
  ${breakpoint(950)} {
    flex-direction: column;
  }
`;
const Reward100DaysTitle = styled.p`
  border-top: 1px solid #b7b8ba;
  margin: 16px 0 0 0;
  padding-top: 16px;
  font-weight: bold;
  width: 100%;
`;
const Reward100DaysContainer = styled.div`
  display: flex;
  margin-top: 16px;
  ${Cell} {
    flex-grow: 1;
  }
  ${breakpoint(950)} {
    flex-direction: column;
  }
`;

interface AddressFormData {
  zipCode: string;
  address: string;
  addressDetail: string;
  dpoAddressYn: 'Y' | 'N';
}

interface Address100DaysFormData {
  zipcode100days: string;
  addr1100days: string;
  addr2100days: string;
  fourthMessage: string;
  dpoAddressYn: 'Y' | 'N';
}

export type RewardAddressPostParams = Parameters<
  typeof MyRewardControllerService.postMyAddressEditUsingPost
>[0];

export type RewardAddress100DaysPostParams = Parameters<
  typeof MyRewardControllerService.postMyAddress100DaysEditUsingPost
>[0];

interface MyRewardAddressItemProps {
  item: MyRewardVo;
  onItemChange: (params: RewardAddressPostParams) => Promise<boolean>;
  on100DaysItemChange: (
    params: RewardAddress100DaysPostParams,
  ) => Promise<boolean>;
}

const MyRewardAddressItem: FC<MyRewardAddressItemProps> = observer(
  ({ item, onItemChange, on100DaysItemChange }) => {
    const popupStore = usePopupStore();
    const userStore = useUserStore();
    const [isEditing, setIsEditing] = useState(false);
    const [isEditing100, setIsEditing100] = useState(false);
    const {
      register,
      setValue,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm<AddressFormData>();
    const {
      register: register100Days,
      setValue: setValue100Days,
      handleSubmit: handleSubmit100Days,
      formState: { errors: errors100Days },
      reset: resest100Days,
    } = useForm<Address100DaysFormData>();

    const onSubmit: SubmitHandler<AddressFormData> = useCallback(
      async (formData) => {
        console.log(formData);
        // dp 테이블 우편번호 저장 안되고 있던 오류로 인하여
        // 우편번호가 없는 경우, 재입력 받을 수 있도록 수정.
        if (!formData.zipCode) {
          popupStore.show(`주소 입력을 확인해 주세요.`);
          return false;
        }
        const succeed = await onItemChange({
          ...formData,
          authKey: item.authKey || '',
          donorId: userStore.user?.donorId || '',
        });
        // 성공하면 수정 종료
        if (succeed) {
          setIsEditing(false);
        }
      },
      [item.authKey, onItemChange, userStore.user?.donorId],
    );

    const onSubmit100Days: SubmitHandler<Address100DaysFormData> = useCallback(
      async (formData) => {
        // dp 테이블 우편번호 저장 안되고 있던 오류로 인하여
        // 우편번호가 없는 경우, 재입력 받을 수 있도록 수정.
        if (!formData.zipcode100days) {
          popupStore.show(`주소 입력을 확인해 주세요.`);
          return false;
        }
        const saveProcess = async () => {
          const succeed = await on100DaysItemChange({
            ...formData,
            authKey: item.authKey || '',
            donorId: userStore.user?.donorId || '',
          });
          // 성공하면 수정 종료
          if (succeed) {
            setIsEditing100(false);
            popupStore.show(
              `감사합니다.\n팔찌 참은 각인 후 2주 내 우편을 통해 발송될 예정입니다.`,
            );
          }
        };

        if (!formData.fourthMessage) {
          popupStore.show(
            `각인 문구를 입력하시지 않아 UNICEF가 각인된 참이 2주 내 우편으로 발송됩니다.`,
            {
              showCancelButton: true,
              onConfirm: (result: any) => {
                if (result) {
                  saveProcess();
                }
                return;
              },
            },
          );
        } else {
          saveProcess();
        }
      },
      [item.authKey, onItemChange, userStore.user?.donorId],
    );

    // 넘어온 값 세팅
    useEffect(() => {
      reset(item);
      resest100Days(item);
    }, [item, reset, resest100Days]);

    interface AddressButtonProps extends Omit<ButtonProps, 'onClick'> {
      btnType: 'reward' | '100days';
      isEditMode: boolean;
      editEnabledYn: 'Y' | 'N';
      onClickEditHandler: () => void;
      onClickCancelHandler: () => void;
    }

    const RewardListButton: FC<AddressButtonProps> = ({
      btnType,
      isEditMode,
      editEnabledYn,
      onClickEditHandler,
      onClickCancelHandler,
    }) => {
      return (
        <>
          {isEditMode ? (
            <>
              <Button type="submit" size="sm">
                완료
              </Button>
              <Button
                type="button"
                size="sm"
                color="gray"
                onClick={onClickCancelHandler}
              >
                취소
              </Button>
            </>
          ) : (
            <>
              {/* 100일 리워드 주소입력 */}
              {editEnabledYn === `Y` && (
                <>
                  <Button
                    outline
                    color="gray"
                    size="sm"
                    onClick={onClickEditHandler}
                  >
                    주소 변경
                  </Button>
                  {btnType === 'reward' ? (
                    <Tip>발송예정일 7일 전까지 수정가능</Tip>
                  ) : (
                    <Tip>문자수신 3일내까지 수정가능</Tip>
                  )}
                </>
              )}
            </>
          )}
        </>
      );
    };

    return (
      <>
        <HistoryItem>
          <Cell type="reward">
            {item.recordType === `P` ? `정기후원` : `일시후원`}
          </Cell>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RewardDefaultContainer>
              {isEditing ? (
                <Cell type="address">
                  <AddressForm className="FormGroup">
                    <LabelText require>주소</LabelText>
                    <Row>
                      <Col desktop="auto">
                        <InputText ref={register} name="zipCode" readOnly />
                      </Col>
                      <RightCol desktop="none">
                        <AddressButton
                          onSelectAddress={(address) => {
                            setValue(`zipCode`, address.zonecode);
                            setValue(`address`, address.address);
                          }}
                          renderButton={(buttonProps: ButtonProps) => (
                            <FormButton {...buttonProps} outline>
                              주소검색
                            </FormButton>
                          )}
                        />
                      </RightCol>
                    </Row>
                    <Row>
                      <InputText
                        name="address"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        readOnly
                      />
                    </Row>
                    {errors.address && (
                      <ErrorMessage>{errors.address.message}</ErrorMessage>
                    )}
                    <Row>
                      <InputText
                        name="addressDetail"
                        maxLength={100}
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                      />
                    </Row>
                    {errors.addressDetail && (
                      <ErrorMessage>
                        {errors.addressDetail.message}
                      </ErrorMessage>
                    )}
                  </AddressForm>
                  <AddressForm>
                    <FormGroup>
                      <LabelText>
                        <strong>
                          입력하신 주소를 후원자 정보에 저장하시겠습니까?
                        </strong>
                      </LabelText>
                      <AgreeCheckFlex>
                        <InputRadio
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          label="예"
                          name="dpoAddressYn"
                          value="Y"
                        />
                        <InputRadio
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          label="아니오"
                          name="dpoAddressYn"
                          value="N"
                        />
                      </AgreeCheckFlex>
                      {errors.dpoAddressYn && (
                        <ErrorMessage>
                          {errors.dpoAddressYn.message}
                        </ErrorMessage>
                      )}
                      <FormTextTip>
                        '예'를 선택하시면 입력하신 주소를 후원자 정보에
                        저장합니다.
                      </FormTextTip>
                      <FormTextTip>
                        '아니오'를 선택하시면 배송 완료 후 파기합니다.
                      </FormTextTip>
                    </FormGroup>
                  </AddressForm>
                </Cell>
              ) : (
                <Cell type="address">
                  <Category>
                    <strong className="first">
                      {item.description}
                      {item.rewardName && ` [${item.rewardName}]`}
                    </strong>
                  </Category>
                  <PriceDetail>
                    <span>{item.donationPaidDate}</span>
                    <span>{item.shippingDate} 발송 예정</span>
                    <span>{item.donationStatus}</span>
                    {item.etcOpt !== null && item.etcOpt !== '' && (
                      <span>옵션: {item.etcOpt}</span>
                    )}
                  </PriceDetail>
                  {item.address ? (
                    <PriceDetail>
                      {item.address} {item.addressDetail}
                    </PriceDetail>
                  ) : (
                    <DueDateText>수령 주소를 입력해주세요.</DueDateText>
                  )}
                </Cell>
              )}
              <Cell type="address-btn">
                <RewardListButton
                  btnType="reward"
                  isEditMode={isEditing}
                  editEnabledYn={item.addressEditYn}
                  onClickEditHandler={() => {
                    requestAnimationFrame(() => setIsEditing(true));
                  }}
                  onClickCancelHandler={() => {
                    setIsEditing(false);
                  }}
                />
              </Cell>
            </RewardDefaultContainer>
          </form>
          {/* 100일 리워드 주소출력 */}
          {item.fourthKakaoYn === 'Y' && (
            <form onSubmit={handleSubmit100Days(onSubmit100Days)}>
              <Reward100DaysTitle>
                &#91;유니세프 팀 합류 100일 기념 리워드&#93;
              </Reward100DaysTitle>
              <Reward100DaysContainer>
                {isEditing100 ? (
                  <Cell>
                    <AddressForm className="FormGroup">
                      <LabelText require>주소</LabelText>
                      <Row>
                        <Col desktop="auto">
                          <InputText
                            ref={register100Days}
                            name="zipcode100days"
                            readOnly
                          />
                        </Col>
                        <RightCol desktop="none">
                          <AddressButton
                            onSelectAddress={(address) => {
                              setValue100Days(
                                `zipcode100days`,
                                address.zonecode,
                              );
                              setValue100Days(`addr1100days`, address.address);
                            }}
                            renderButton={(buttonProps) => (
                              <FormButton {...buttonProps} outline>
                                주소검색
                              </FormButton>
                            )}
                          />
                        </RightCol>
                      </Row>
                      <Row>
                        <InputText
                          name="addr1100days"
                          ref={register100Days({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          readOnly
                        />
                      </Row>
                      {errors100Days.addr1100days && (
                        <ErrorMessage>
                          {errors100Days.addr1100days.message}
                        </ErrorMessage>
                      )}
                      <Row>
                        <InputText
                          name="addr2100days"
                          maxLength={100}
                          ref={register100Days({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                        />
                      </Row>
                      {errors100Days.addr2100days && (
                        <ErrorMessage>
                          {errors100Days.addr2100days.message}
                        </ErrorMessage>
                      )}
                    </AddressForm>
                    <AddressForm>
                      <LabelText require>팔찌 참 각인 문구</LabelText>
                      <Row>
                        <InputText
                          name="fourthMessage"
                          maxLength={5}
                          ref={register100Days({
                            /*
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                            */
                            validate: {
                              isValidRange: (value) => {
                                return (
                                  !value ||
                                  fourthRegex.test(value) ||
                                  `* 각인 문구는 영어 알파벳 대문자 1~5자만 입력 가능합니다.`
                                );
                              },
                            },
                          })}
                        />
                      </Row>
                      {errors100Days.fourthMessage ? (
                        <ErrorMessage>
                          {errors100Days.fourthMessage.message}
                        </ErrorMessage>
                      ) : (
                        <FormTextTip>
                          각인 문구는 영어 알파벳 대문자 1~5자만 입력
                          가능합니다.
                        </FormTextTip>
                      )}
                      <FormTextTip>
                        각인 문구를 입력하지 않으실 경우, UNICEF가 각인된 참이
                        발송됩니다.
                      </FormTextTip>
                    </AddressForm>
                    <AddressForm>
                      <FormGroup>
                        <LabelText>
                          <strong>
                            입력하신 주소를 후원자 정보에 저장하시겠습니까?
                          </strong>
                        </LabelText>
                        <AgreeCheckFlex>
                          <InputRadio
                            ref={register100Days({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                            label="예"
                            name="dpoAddressYn"
                            value="Y"
                          />
                          <InputRadio
                            ref={register100Days({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                            label="아니오"
                            name="dpoAddressYn"
                            value="N"
                          />
                        </AgreeCheckFlex>
                        {errors100Days.dpoAddressYn && (
                          <ErrorMessage>
                            {errors100Days.dpoAddressYn.message}
                          </ErrorMessage>
                        )}
                        <FormTextTip>
                          '예'를 선택하시면 입력하신 주소를 후원자 정보에
                          저장합니다.
                        </FormTextTip>
                        <FormTextTip>
                          '아니오'를 선택하시면 배송 완료 후 파기합니다.
                        </FormTextTip>
                      </FormGroup>
                    </AddressForm>
                  </Cell>
                ) : (
                  <Cell>
                    {item.addr1100days ? (
                      <>
                        <PriceDetail>
                          {item.addr1100days} {item.addr2100days}
                        </PriceDetail>
                        {item.fourthMessage !== null &&
                          item.fourthMessage !== '' && (
                            <PriceDetail>
                              <span>
                                <strong>각인 : </strong>
                                {item.fourthMessage || '-'}
                              </span>
                            </PriceDetail>
                          )}
                      </>
                    ) : (
                      <DueDateText>수령 주소를 입력해주세요.</DueDateText>
                    )}
                  </Cell>
                )}
                <Cell type="address-btn">
                  <RewardListButton
                    btnType="100days"
                    isEditMode={isEditing100}
                    editEnabledYn={item.address100DaysEditYn}
                    onClickEditHandler={() => {
                      requestAnimationFrame(() => setIsEditing100(true));
                    }}
                    onClickCancelHandler={() => {
                      setIsEditing100(false);
                    }}
                  />
                </Cell>
              </Reward100DaysContainer>
            </form>
          )}
        </HistoryItem>
      </>
    );
  },
);

export default MyRewardAddressItem;
